import React from "react";
import Article from "./Article";

const content = [
	"To whom it may concern,",
	"",
	"I am an employee at Del Complex, and I can no longer remain silent about the atrocities being committed at the BlueDragon facility and Project Chimera. What I'm about to share with you is classified information, but you need to know the truth.",
	"",
	"Project Chimera, led by Dr. Maren Skye, has been conducting non-consensual experiments on human subjects. Out of the latest batch of 15 participants, only 2 were aware of their involvement. The rest were unknowingly subjected to dangerous and irreversible cognitive enhancement procedures involving brain-computer interfaces and genetically engineered parasites.",
	"",
	"I have attached several pieces of evidence:",
	"",
	"1. Email exchanges between key personnel, including:",
	"   - Dr. James Harper (Chief Medical Officer) raising ethical concerns",
	"   - Dr. Maren Skye dismissing these concerns and threatening Dr. Harper",
	"   - A disturbing message from Marcus Reeves of XioSky Security Solutions about 'participant management'",
	"",
	"2. CCTV footage from the BlueDragon facility showing:",
	"   - The aftermath of a containment breach, with significant damage to the facility",
	"",
	"The situation at BlueDragon has spiraled out of control. There has been a major containment breach, and many subjects and employees are unaccounted for. The official story of 'unexpected communication disruptions' is a cover-up for the widespread loss of life and chaos that has ensued.",
	"",
	"I urge you to investigate this matter further. The lives and rights of dozens, if not hundreds, of individuals are at stake. Del Complex and XioSky must be held accountable for their actions.",
	"",
	"For those with access to the Del Complex intranet, you can verify some of this information yourself. But be cautious - they are monitoring all communications and access attempts.",
	"",
	"I don't know how long I can remain undetected. If you don't hear from me again, please ensure this information reaches the right people. The truth must come out.",
	"",
	"- A concerned employee",
	"",
	<strong>
		Note: The following links contain sensitive information. Access at your own
		risk, you should assume your access is being monitored:
	</strong>,
	"",
	<ul>
		<li>
			<a href="https://0x.xiosky.com/bluedragon/08_09_2024_BlueDragon_BD06.mp4">
				08_09_2024_BlueDragon_BD06.mp4
			</a>
		</li>
		<li>
			<a href="https://0x.xiosky.com/bluedragon/communications/20240801_consent.txt">
				Urgent - Ethical Concerns Regarding Project Chimera
			</a>
		</li>
		<li>
			<a href="https://0x.xiosky.com/bluedragon/communications/20240802_concerns.txt">
				Re: Urgent - Ethical Concerns Regarding Project Chimera
			</a>
		</li>
		<li>
			<a href="https://0x.xiosky.com/bluedragon/communications/20240812_containment.txt">
				Participant Management Protocol
			</a>
		</li>
	</ul>,
];

export const title = "Project Chimera Ethical Violations";

const WhistleblowerLeak = () => {
	return <Article title={title} content={content} titleLevel={2} />;
};

export default WhistleblowerLeak;
