import React from "react";
import Article from "./Article";
import { useEffect, useState } from "react";
import WalletConnect from "./WalletConnect";
import MintToken from "./MintToken";
import MetaTags from "./MetaTags";

const BlueDragonRecords = () => {
	return (
		<div className="article-container">
			<div className="blueDragonRecords" style={{}}>
				<MetaTags
					titleSuffix="Authorized Access Only"
					description="Authorized Access Only"
					path={location.pathname}
				/>
				<div className="spacerShort"></div>
				<p>▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮</p>
				<p>▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮</p>
				<p>▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮</p>
				<Article
					title=""
					content={["INTERNAL ARCHIVE ENDPOINT", "Blue Dragon Records."]}
					titleLevel={1}
				/>
				<div className="spacerShort"></div>
				<p>▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮</p>
				<p>▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮</p>
				<p>▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮</p>
				<div>
					<WalletConnect />

					<MintToken
						mintButtonText="Claim Document"
						priceOverride="0.0005"
						claimId="3797836016"
						imageSrc="/media/archival_media/BD24-0828-A.jpg"
						fileSrc="/media/archival_media/BD24-0828-A.pdf"
						alt="Document Access Request #BD24-0828-A"
					/>
				</div>
				<p>▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮</p>
				<p>▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮</p>
				<p>▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮</p>
				<p>▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮</p>
			</div>
		</div>
	);
};

export default BlueDragonRecords;
