import React from "react";
import Article from "./Article";

const content = [
	"August 27, 2024",
	"",
	"Attention All Personnel,",
	"",
	"There is an ongoing operational situation impacting our external partners at the XioSky BlueDragon facility. Your discretion and cooperation are mandatory to ensure the security and continuity of our operations.",
	"",
	"Please see the Resources tab for the latest CCTV footage from the BlueDragon facility. If you have any information or concerns, please report them to your Department Lead immediately.",
	"",
	"Current Status:",
	"",
	"1. Unexpected communication disruptions have been detected within the BlueDragon facility.",
	"2. The nature and extent of these disruptions are currently under investigation.",
	"3. XioSky Risk Department Staff are actively engaged in resolving the situation.",
	"4. Standard protocols for the affected facility are temporarily suspended.",
	"",
	"",
	"Immediate Actions Required:",
	"",
	<>
		- Complete this{" "}
		<a href="https://forms.gle/HzHBepYEhXVormCN6">
			Mandatory Compliance Survey
		</a>
		.
	</>,
	"- Maintain normal operations within your assigned areas.",
	"- Do not attempt to contact or access the BlueDragon facility until further notice.",
	"- Be prepared for potential adjustments to daily routines, your security badge may have access revoked to certain areas.",
	"- Stay alert for further official updates through approved channels.",
	"",
	"Thank you,",
	"",
	"Dr. Maren Skye",
	"Lead Researcher, Brain Worms Research Program",
	"Del Complex Cognitive Development Group",
];

export const title = "PRIORITY NOTICE: Operational Status Update";
const PortalNewsBlueDragonUpdate = () => {
	return <Article title={title} content={content} titleLevel={2} />;
};

export default PortalNewsBlueDragonUpdate;
