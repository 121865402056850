import React from "react";
import Portal from "./Portal";
import PortalNewsHiringEnd, { title as endTitle } from "./PortalNewsHiringEnd";
import PortalCandidateFooter from "./PortalCandidateFooter";

import PortalNewsOnboardingChecklist, {
	title as onboardTitle,
} from "./PortalNewsOnboardingChecklist";

import WhistleblowerLeak, {
	title as whistleblowerTitle,
} from "./PortalNewsWhistleblowerLeak";

import PortalTragicIncident, {
	title as tragicIncidentTitle,
} from "./PortalTragicIncident";

const PortalCandidates = () => {
	const tabs = [
		{ id: "home", label: "Home" },
		{ id: "jobOpportunities", label: "Job Opportunities" },
	];

	const newsArticles = [
		{ component: PortalTragicIncident, title: tragicIncidentTitle },
		{ component: WhistleblowerLeak, title: whistleblowerTitle },
		{ component: PortalNewsOnboardingChecklist, title: onboardTitle },
		{ component: PortalNewsHiringEnd, title: endTitle },
	];

	return (
		<Portal
			category="Candidate"
			tabs={tabs}
			newsArticles={newsArticles}
			footerComponent={<PortalCandidateFooter />}
		/>
	);
};

export default PortalCandidates;
