import React from "react";
import Article from "./Article";

const content = [
	"September 03, 2024",
	"",
	"RE: Document Access Request #BD24-0828-A",
	"",
	"Dear Requester,",
	"",
	"Your request for access to Blue Dragon facility documents has been processed.",
	"",
	"",

	<a href="https://0x.xiosky.com/bluedragon/communications/index.html">
		View Records
	</a>,
	"",
	"",

	"This is an automated message.",
	"",
	"",
	"Del Complex Information Security",
	"Automated Document Access System",
];

export const title = "Automated Response: Document Access Request #BD24-0828-A";

const AutomatedDocumentResponse = () => {
	return <Article title={title} content={content} titleLevel={2} />;
};

export default AutomatedDocumentResponse;
