import React from "react";
import Article from "./Article";

const content = [
	"September 9, 2024",
	"",
	"Dear Del Complex Employees, Staff, and Community,",
	"",
	"It is with deep regret that we must inform you of a tragic incident that occurred at one of our research facilities. Our thoughts are with those affected by this unfortunate event.",
	"",
	"Official Press Release:",
	"",
	"FOR IMMEDIATE RELEASE",
	"",
	"Del Complex Addresses Incident at Research Facility",
	"",
	"SUNNYVALE, CA - September 9, 2024 - Del Complex regrets to announce that an accident has occurred at one of our advanced research facilities, resulting in loss of life. The incident is currently under investigation, and we are fully cooperating with relevant authorities.",
	"",
	"At Del Complex, the safety and well-being of our employees and research participants are our highest priorities. We are committed to understanding the circumstances that led to this tragic event and to implementing any necessary changes to prevent future incidents.",
	"",
	"Del Complex has activated its Emergency Response Team and is providing support services to employees and families affected by this incident. We have also temporarily suspended operations at the affected facility pending a thorough safety review.",
	"",
	"We appreciate the dedication of our employees during this difficult time and ask for privacy as we work through this challenging situation.",
	"",
	"--- End of Press Release ---",
	"",
	"We appreciate your continued dedication to our mission during this challenging time.",
	"",
	"Sincerely,",
	"",
	"Corporate Communications",
	"Del Complex",
];

export const title =
	"IMPORTANT: Official Statement Regarding Research Facility Incident";

const TragicIncident = () => {
	return <Article title={title} content={content} titleLevel={2} />;
};

export default TragicIncident;
